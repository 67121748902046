/* Basic styling */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Colors */
  --black: #262626;
  --grey: #262626;
  --white: #fff;
  --background: "#d6cfbf";
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} 

body {
  background-color: var(--background);
  font-family: "Poppins", sans-serif;
}

@font-face {
  font-family: "Robote_bold";
  src: url("Fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Robote";
  src: url("Fonts/Roboto-Light.ttf") format("truetype");
}

/* In your CSS file */
.hide-scrollbar {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For IE and Edge */

  /* For Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}
