/* Start Component*/

/* End Component*/

/* Start Glable Rules */

ul {
  margin: 0;
  padding: 0;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  /* background: #dfdfdf !important; */
  margin: 0;
  background-color: #eee;
  position: relative;
}

a {
  color: inherit;
  text-decoration: none;
}


button:active {
  outline: none;
}

button:focus {
  outline: none;
}
.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}
.bg-text {
  width: 70%;

  z-index: 2;
  text-align: center;
  color: aliceblue;
  font-weight: 600;
  box-shadow: 10px 10px 30px black;
  padding: 3rem;
  border: 2px solid white;
  border-radius: 10px;
  text-shadow: 1px 1px 3px black;
  margin: "1rem 0";
}

:root {
  --bg-color: #e5e7eb;
  --button-bg-color: #1a202c;
  --button-text-color: #ffffff;
  --border-color: #000000;
}

.Robote_bold {
  font-family: "Robote_bold";
}

.Robote {
  font-family: "Robote";
}
.line {
  height: 20px;
  /* Increased height for better visibility of the curve */
  background-color: black;
  /* margin: 10px 0; */
  width: 200px;
  /* Uniform width for all lines */
  /* padding: 10px; */
}

.bent {
  width: 95%;
  height: 150%;
  border: solid 5px;
  border-color: transparent transparent transparent;
  border-radius: 100% 100% 0 0;
  margin-left: 28px;
  margin-top: -11px;
}

.bent2 {
  width: 95%;
  height: 150%;
  border: solid 5px;
  border-color: transparent transparent transparent;
  border-radius: 100% 100% 0 0;
  margin-left: -22px;
  margin-top: -11px;
}


.css-13efy8c-MuiStack-root{
  min-height: 500px;
}
.swiper-wrapper{
  height: 500px;
}
.swiper{
    height: 500px;

}
.css-1tj1ga8-MuiStack-root{
  height: 500px;
}
.css-r7ydwa-MuiStack-root{
  height: 500px;

}
